// src/UploaderForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { readAndCompressImage } from 'browser-image-resizer';
import { format } from 'date-fns';

const config = {
    quality: 0.5,
    maxWidth: 800,
    maxHeight: 600,
    debug: true,
    autoRotate: true,
};

const UploaderForm = () => {
    const [petName, setPetName] = useState('');
    const [customerID, setCustomerID] = useState('');
    const [shopifyOriderID, setShopifyOrderID] = useState('');
    const [shopifyProductID, setShopifyProductID] = useState('');
    const [productID, setProductID] = useState('');
    const [shopifyID, setShopifyID] = useState('');
    const [classification, setClassification] = useState('');
    const [breed, setBreed] = useState('');
    const [designType,setDesignType] = useState('');
    const [size,setSize] = useState('');
    const [photography,setPhotography] = useState('');
    const [nameBorder,setNameBorder] = useState('');
    const [frameColor,setFrameColor] = useState('');


    const [birthDate, setBirthDate] = useState(new Date());
    const [border, setBorder] = useState(false);
    const [files, setFiles] = useState([]);
    const [resizedImages, setResizedImages] = useState([]);
    const [resizedImagePreviews, setResizedImagePreviews] = useState([]);

    const handleFileChange = async (event) => {
        const selectedFiles = Array.from(event.target.files);
        const resizedImagesArray = [];
        const resizedImagePreviewsArray = [];

        for (const file of selectedFiles) {
            const resizedImage = await readAndCompressImage(file, config);
            resizedImagesArray.push(resizedImage);
            const resizedImageUrl = URL.createObjectURL(resizedImage);
            resizedImagePreviewsArray.push(resizedImageUrl);
        }

        setFiles(selectedFiles);
        setResizedImages(resizedImagesArray);
        setResizedImagePreviews(resizedImagePreviewsArray);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        
        resizedImages.forEach((resizedImage, index) => {
            const originalFile = files[index];
            const resizedFile = new File([resizedImage], originalFile.name, { type: resizedImage.type });
            formData.append('files', resizedFile);      
      });

        formData.append('customerID',customerID);
        formData.append('shopifyOrderID',shopifyOriderID);
        formData.append('shopifyProductID',shopifyProductID);
        formData.append('productID',productID);
        formData.append('shopifyID',shopifyID);
        formData.append('classification',classification);
        formData.append('breed',breed);
        formData.append('petName', petName);
        formData.append('birthDate', format(birthDate, 'yyyy-MM-dd'));
        formData.append('border', border);
        formData.append('designType', designType);
        formData.append('size', size);
        formData.append('nameBorder', nameBorder);
        formData.append('frameColor', frameColor);

        try {
            const response = await axios.post('https://throbbing-rice-d2ed.art-group-ai.workers.dev', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',              
                },
                withCredentials: true,


            });
            console.log('Files uploaded successfully', response.data);
        } catch (error) {
            console.error('Error uploading files', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>
                   Customer ID:
                    <input type="text" value={customerID} onChange={(e) => setCustomerID(e.target.value)} />
                </label>
            </div>
            <div>
                <label>
                    Shopify Order ID:
                    <input type="text" value={shopifyOriderID} onChange={(e) => setShopifyOrderID(e.target.value)} />
                </label>
            </div>
            <div>
                <label>
                    Shopify Product ID:
                    <input type="text" value={shopifyProductID} onChange={(e) => setShopifyProductID(e.target.value)} />
                </label>
            </div>
            <div>
                <label>
                    Shopify ID:
                    <input type="text" value={shopifyID} onChange={(e) => setShopifyID(e.target.value)} />
                </label>
            </div>
            <div>
                <label>
                    Product ID:
                    <input type="text" value={productID} onChange={(e) => setProductID(e.target.value)} />
                </label>
            </div>
            <div>
                <label>
                    Pet Name:
                    <input type="text" value={petName} onChange={(e) => setPetName(e.target.value)} />
                </label>
            </div>
            <div>
                <label>
                    Classification:
                    <input type="text" value={classification} onChange={(e) => setClassification(e.target.value)} />
                </label>
            </div>
            <div>
                <label>
                    Breed:
                    <input type="text" value={breed} onChange={(e) => setBreed(e.target.value)} />
                </label>
            </div>
            
            <div>
                <label>
                    Birth Date:
                    <input type="date" value={format(birthDate, 'yyyy-MM-dd')} onChange={(e) => setBirthDate(new Date(e.target.value))} />
                </label>
            </div>
            <div>
                <label>
                    Border:
                    <input type="checkbox" checked={border} onChange={(e) => setBorder(e.target.checked)} />
                </label>
            </div>
            
            <div>
                <label>
                    Images:
                    <input type="file" accept="image/*" multiple onChange={handleFileChange} />
                </label>
            </div>
            {resizedImagePreviews.length > 0 && (
                <div>
                    <h3>Resized Images</h3>
                    {resizedImagePreviews.map((imageUrl, index) => (
                        <img key={index} src={imageUrl} alt={`Resized ${index}`} />
                    ))}
                </div>
            )}
            <br>
            </br>
            <br></br>
            <h4>Product Information</h4>

            <div>
                <label>
                    Design Type:
                    <input type="text" value={designType} onChange={(e) => setDesignType(e.target.value)} />
                </label>
            </div>
            <div>
                <label>
                    Size:
                    <input type="text" value={size} onChange={(e) => setSize(e.target.value)} />
                </label>
            </div>
            <div>
                <label>
                    Photography (Portrait / Landscpae):
                    <input type="text" value={photography} onChange={(e) => setPhotography(e.target.value)} />
                </label>
            </div>

            <div>
                <label>
                    Name/Border/No Border:
                    <input type="text" value={nameBorder} onChange={(e) => setNameBorder(e.target.value)} />
                </label>
            </div>

            <div>
                <label>
                    Frame Color:
                    <input type="text" value={frameColor} onChange={(e) => setFrameColor(e.target.value)} />
                </label>
            </div>
           
            <button type="submit">Upload</button>
        </form>
    );
};

export default UploaderForm;